import RadioButton from '../../Atoms/RadioButton/RadioButton';
import { animation, timings } from 'Theme/Settings/animation';

import { styled } from 'stitches.config';

type PropType = {
  image?: JSX.Element;
  content: JSX.Element;
  onClick: () => void;
  isActive: boolean;
  radioBg?: 'main' | 'secondary';
  isError?: boolean;
};

const SingleOptionCard = ({
  image,
  content,
  onClick,
  isActive,
  radioBg = 'main',
  isError = false,
}: PropType) => {
  return (
    <SingleOptionCardContainer
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      isActive={isActive}
      isError={isError}
    >
      {image}
      <SingleOptionCardContent>
        {content}
        <SingleOptionCardRadio radioBg={radioBg}>
          <RadioButton
            css={{ mr: 0 }}
            isChecked={isActive}
            onChange={() => {}}
            radioBackgroundColor="primary3"
          />
        </SingleOptionCardRadio>
      </SingleOptionCardContent>
    </SingleOptionCardContainer>
  );
};

const SingleOptionCardContainer = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  width: '100%',
  borderWidth: '1px',
  borderColor: 'transparent',
  transition: `border ${timings.oneFifth} ${animation.timingFn}`,
  variants: {
    isActive: {
      true: {
        borderColor: '$JE68GreenPrimary',
      },
    },
    isError: {
      true: {
        borderColor: '$errorColor',
      },
    },
  },
});

const SingleOptionCardContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const SingleOptionCardRadio = styled('div', {
  display: 'flex',
  alignItems: 'center',
  px: 6,
  variants: {
    radioBg: {
      main: {
        backgroundColor: '$primary3',
      },
      secondary: {
        backgroundColor: '$primary2',
      },
    },
  },
});

export default SingleOptionCard;
