import { H2, H3 } from 'Atoms/Typography/Headings/Heading';
import Modal from 'Organisms/Modal/Modal';
import { useEffect, useRef, useState } from 'react';
import {
  GetBrands,
  GetModels,
  GetYears,
} from 'Shared/MotorcycleModel/MotorcycleModel';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import BMW from '../../Shared/Images/brands/BMW.svg';
import KTM from '../../Shared/Images/brands/KTM.svg';
import Triumph from '../../Shared/Images/brands/Triumph.svg';
import { styled } from 'stitches.config';
import StepCircle from '../../Pages/AccountPage/AccountTab/AccountGarage/StepCircle';
import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import RadioButton from 'Atoms/RadioButton/RadioButton';
import LoadingCircle from 'Atoms/Loaders/LoadingCircle';
import MyGarageBikeModel from 'Models/MyGarage/MyGarageBikeModel.interface';
import { ENUM_KTM, ENUM_BMW, ENUM_TRIUMPH } from 'Shared/Constants/brand';
import Button from 'Atoms/Buttons/Button';

type PropTypes = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  handleAdd: (selected: SelectedType) => void;
  buttonText: string;
};

export type SelectedType = {
  brand: string;
  year: string;
  model: string;
  modelName: string;
};

const INITIAL_SELECTED_STATE = {
  brand: '',
  year: '',
  model: '',
  modelName: '',
};

const renderBrandLogo = (currentBrand: string) => {
  switch (currentBrand) {
    case ENUM_BMW:
      return BMW;
    case ENUM_KTM:
      return KTM;
    case ENUM_TRIUMPH:
      return Triumph;
    default:
      return undefined;
  }
};

const AddMotorcycleModal = ({
  showModal,
  setShowModal,
  handleAdd,
  buttonText,
}: PropTypes) => {
  const [brands, setBrands] = useState<string[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [models, setModels] = useState<MyGarageBikeModel[]>([]);

  const [pending, setPending] = useState<boolean>(false);

  const yearRef = useRef<HTMLButtonElement>(null);
  const modelRef = useRef<HTMLButtonElement>(null);

  const [selected, setSelected] = useState<SelectedType>(
    INITIAL_SELECTED_STATE
  );

  const {
    myGarageLabels: { addBike },
    motorcycleModelFinderLabels: { brand, year, model },
  } = useTranslationData();

  useEffect(() => {
    async function getBrands() {
      setPending(true);

      const res = await GetBrands();
      setBrands(res.brands);

      setPending(false);
    }

    getBrands();
  }, []);

  const handleBrandClick = async (item: string) => {
    setPending(true);

    const res = await GetYears(item);

    setYears(res.years);

    setSelected({ brand: item, year: '', model: '', modelName: '' });

    yearRef.current?.scrollIntoView({ behavior: 'smooth' });

    setPending(false);
  };

  const handleYearClick = async (item: string) => {
    setPending(true);

    const res = await GetModels(selected.brand, item);
    setModels(res.models);

    setSelected({ ...selected, year: item, model: '' });

    modelRef.current?.scrollIntoView({ behavior: 'smooth' });

    setPending(false);
  };

  const resetSelectedState = () => {
    setSelected(INITIAL_SELECTED_STATE);
  };

  const hasSelectedBrand = selected.brand !== '';
  const hasSelectedYear = selected.year !== '';
  const hasSelectedModel = selected.model !== '';

  return (
    <>
      <Modal
        showModal={showModal}
        toggle={() => setShowModal(!showModal)}
        noPadding
      >
        <ModalContent>
          <Section>
            <H2 css={{ mb: 8 }}>{addBike}</H2>
          </Section>

          <StepHeader>
            <StepCircle number={1} /> <H3 noMargin>{brand}</H3>
          </StepHeader>
          <StepContent css={{ py: 8 }}>
            {brands.map((item, index) => (
              <SingleOptionCard
                key={item + index}
                isActive={selected.brand === item}
                content={
                  <BrandHeader>
                    <BrandImage src={renderBrandLogo(item)} />
                  </BrandHeader>
                }
                radioBg="secondary"
                onClick={() => handleBrandClick(item)}
              />
            ))}
          </StepContent>

          <StepHeader inactive={!hasSelectedBrand} ref={yearRef}>
            <StepCircle number={2} /> <H3 noMargin>{year}</H3>
          </StepHeader>
          {hasSelectedBrand && (
            <StepContent>
              {years.map((item, index) => (
                <RadioButton
                  key={item + index}
                  text={item}
                  isChecked={selected.year === item}
                  onChange={() => handleYearClick(item)}
                />
              ))}
            </StepContent>
          )}

          <StepHeader inactive={!hasSelectedYear} ref={modelRef}>
            <StepCircle number={3} /> <H3 noMargin>{model}</H3>
          </StepHeader>
          {hasSelectedYear && (
            <StepContent>
              {models.map((item, index) => (
                <RadioButton
                  key={item.modelId + index}
                  text={item.modelName}
                  isChecked={selected.model === item.modelId}
                  onChange={() => {
                    setSelected({
                      ...selected,
                      model: item.modelId,
                      modelName: item.modelName,
                    });
                  }}
                />
              ))}
            </StepContent>
          )}

          {pending && (
            <LoadingContainer>
              <LoadingCircle />
            </LoadingContainer>
          )}
        </ModalContent>

        <ModalCTA>
          <Button
            onClick={() => {
              handleAdd(selected);

              setShowModal(false);

              resetSelectedState();
            }}
            type="secondary"
            fullWidth
            disabled={
              !hasSelectedBrand || !hasSelectedYear || !hasSelectedModel
            }
          >
            {buttonText}
          </Button>
        </ModalCTA>
      </Modal>
    </>
  );
};

const StepHeader = styled('button', {
  backgroundColor: '$primary3',
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  py: 6,
  px: 4,
  gap: 16,
  '@mediaMinLarge': {
    p: 8,
  },
  mb: 2,
  variants: {
    inactive: {
      true: {
        opacity: 0.5,
      },
    },
  },
});

const StepContent = styled('div', {
  px: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  pt: 6,
  pb: 8,
  '@mediaMinLarge': {
    px: 6,
  },
});

const BrandHeader = styled('div', {
  backgroundColor: '$primary3',
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  height: '64px',
  py: 6,
  px: 4,
  gap: 16,
  '@mediaMinLarge': {
    px: 6,
  },
});

const BrandImage = styled('img', {
  width: 88,
  objectFit: 'contain',
});

const Section = styled('div', {
  pt: 4,
  px: 4,
});

const LoadingContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const ModalCTA = styled('div', {
  p: 4,
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: '$primary4',
  '@mediaMinLarge': {
    px: 6,
    py: 8,
  },
});

const ModalContent = styled('div', {
  height: '85vh',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

export default AddMotorcycleModal;
