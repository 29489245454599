const motorcycleModelApiUrl = '/MotorcycleModel';

export async function GetBrands() {
  const res = await fetch(`${motorcycleModelApiUrl}/GetBrands`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    return await res.json();
  }
}

export async function GetYears(brand: string) {
  const res = await fetch(`${motorcycleModelApiUrl}/GetYears?brand=${brand}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    return await res.json();
  }
}

export async function GetModels(brand: string, year: string) {
  const res = await fetch(
    `${motorcycleModelApiUrl}/GetModels?brand=${brand}&year=${year}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    return await res.json();
  }
}
