import { styled } from 'stitches.config';

type PropTypes = {
  number: number;
};

const StepCircle = ({ number }: PropTypes) => {
  return <StepCircleContainer>{number}</StepCircleContainer>;
};

const StepCircleContainer = styled('div', {
  backgroundColor: '$primary4',
  w: 8,
  h: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  br: '50%',
  fontFamily: '$fontPrimary400',
  letterSpacing: '$ls1',
  fs: 8,
  lineHeight: '$lh125',
});

export default StepCircle;
