import { updateUserState } from 'Shared/Hooks/useQueryUserState';

const myGarageApiUrl = '/MyGarage';

export async function AddBike(brand: string, year: string, modelId: string) {
  const res = await fetch(
    `${myGarageApiUrl}/AddBike?modelId=${modelId}&brand=${brand}&year=${year}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    return await res.json();
  }
}

export async function GetBikes() {
  const res = await fetch(`${myGarageApiUrl}/GetBikes`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    return await res.json();
  }
}

export async function RemoveBike(id: string) {
  const res = await fetch(`${myGarageApiUrl}/RemoveBike?id=${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    return await res.json();
  }
}

export async function SetPrimaryBike(id: string) {
  const res = await fetch(`${myGarageApiUrl}/SetPrimaryBike?id=${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    // since primary bike is stored in userstate, we'll need to update it also to reflect changes in UI
    updateUserState();

    return await res.json();
  }
}

export async function GetPrimaryBike() {
  const res = await fetch(`${myGarageApiUrl}/GetPrimaryBike`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    return await res.json();
  }
}

export async function UnsetPrimaryBike() {
  const res = await fetch(`${myGarageApiUrl}/UnsetPrimaryBike`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    return;
  }
}
